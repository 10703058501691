import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Typography, Grid, TextField, DialogActions, Button, Box, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Thumbnail from './Thumbnail';

const EditVideo = ({ video, onUpdate, onClose }) => {
	const [ id, setId ] = useState(null);
	const [ title, setTitle ] = useState('');
	const [ description, setDescription ] = useState('');
	const [ thumbnail, setThumbnail ] = useState(1);
	const { status, key, version } = video;

	useEffect(() => {
		const { _id, title, description, thumbnail } = video;
		setId(_id);
		setTitle(title);
		setDescription(description);
		setThumbnail(thumbnail);
	}, [ video ]);

	const closeEditDialog = () => {
		setId(null);
		setTitle('');
		setDescription('');
		onClose();
	};

	const save = () => {
		onUpdate({ _id: id, title, description, thumbnail });
		closeEditDialog();
	};

	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			open={Boolean(id)}
			onClose={closeEditDialog}
		>
			<DialogContent>
				<Grid container direction="column" spacing={3}>
					<Grid item>
						<Typography>You may edit video metadata until the video has been approved.</Typography>
					</Grid>
					<Grid item>
						<TextField
							label="Title"
							variant="outlined"
							type="text"
							fullWidth
							value={title || ''}
							onChange={e => setTitle(e.target.value)}
							disabled={status === 1}
						/>
					</Grid>
					<Grid item>
						<TextField
							label="Description"
							variant="outlined"
							type="text"
							fullWidth
							multiline
							value={description || ''}
							onChange={e => setDescription(e.target.value)}
							disabled={status === 1}
						/>
					</Grid>
					<Grid item>
						<Typography variant="h5" gutterBottom>
							Thumbnail <Tooltip title="Image preview of your video" placement="right"><Info/></Tooltip>
						</Typography>
						<Typography variant="body2">
							One thumbnail is captured at the beginning of the video, then every 3-5 seconds.
						</Typography>
						<Box mb={5}>
							<Thumbnail video_key={key} version={version} thumbnail={thumbnail} width="100%"/>
						</Box>
						<TextField
							label="Thumbnail Image"
							variant="outlined"
							type="number"
							fullWidth
							value={thumbnail || 0}
							onChange={e => setThumbnail(e.target.value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={closeEditDialog}>Cancel</Button>
				<Button color="primary" onClick={save}>Update</Button>
			</DialogActions>
		</Dialog>
	);
};

EditVideo.propTypes = {
	video: PropTypes.shape({
		_id: PropTypes.string,
		title: PropTypes.string,
		description: PropTypes.string,
		thumbnail: PropTypes.number,
		status: PropTypes.number,
		key: PropTypes.string,
		version: PropTypes.number,
	}).isRequired,
	onUpdate: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default EditVideo;
