import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	Grid,
	Typography,
	Tooltip,
	Button,
	Box,
} from '@material-ui/core';
import {
	OndemandVideo,
	Equalizer,
	Edit,
	Delete,
} from '@material-ui/icons';

import PropTypes from 'prop-types';
import Thumbnail from './Thumbnail';
import VideoTimeLine from './VideoTimeLine';

const Video = ({ video, toPreview, toEdit, toDelete, includeBorder }) => {
	const history = useHistory();

	const { _id, title, description, status, comments, createdAt, conversionStatus, key, version, thumbnail } = video;
	return (
		<Box p={2} borderBottom={includeBorder ? 1 : 0} style={{ borderBottomColor: 'grey' }}>
			<Grid container direction="row" spacing={1}>
				<Grid item xs={12} sm={3}>
					<Grid container direction="column">
						<Grid item>
							<Typography color="textSecondary">Title</Typography>
							<Typography variant="h6">{title}</Typography>
						</Grid>
						<Grid item>
							<Thumbnail video_key={key} version={version} thumbnail={thumbnail} width="100%"/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={3}>
					<Typography color="textSecondary">Description</Typography>
					<Typography variant="body1">{description}</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<VideoTimeLine status={status} createdAt={createdAt} comments={comments} conversionStatus={conversionStatus}/>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Grid container direction="row" justify="center">
						<Grid item>
							<Tooltip title="Preview">
								<span>
									<Button disabled={conversionStatus !== 1} onClick={() => toPreview(key, version)}>
										<OndemandVideo />
									</Button>
								</span>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip title="Views">
								<span>
									<Button disabled={conversionStatus !== 1} color="primary" onClick={()=>history.push(`/views/${_id}`,{ video })}>
										<Equalizer/>
									</Button>
								</span>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip title="Edit">
								<Button onClick={() => toEdit(video)}>
									<Edit />
								</Button>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip title="Delete">
								<Button onClick={() => toDelete(_id)}>
									<Delete />
								</Button>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

Video.propTypes = {
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		status: PropTypes.number.isRequired,
		comments: PropTypes.string,
		createdAt: PropTypes.string.isRequired,
		conversionStatus: PropTypes.number.isRequired,
		key: PropTypes.string.isRequired,
		version: PropTypes.number,
		thumbnail: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
	}).isRequired,
	toPreview: PropTypes.func.isRequired,
	toEdit: PropTypes.func.isRequired,
	toDelete: PropTypes.func.isRequired,
	includeBorder: PropTypes.bool.isRequired,
};

export default Video;
