import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const BASE_URL = 'https://d14t5ygliatuz4.cloudfront.net';

const Thumbnail = ({ video_key, version, thumbnail, height, width }) => {
	const [ source, setSource ] = useState('');

	useEffect(() => {
		const thumbnailNumber = thumbnail != null ? String(thumbnail).padStart(7, '0') : '0000001';
		setSource(`${BASE_URL}/${video_key}_${version}/thumbnails/${video_key}_${version}_tumb.${thumbnailNumber}.jpg`);
	}, [ video_key, version, thumbnail ]);

	const onError = () => {
		setSource('');
	};

	if (source === '' || version == null || thumbnail == null || video_key == null) {
		return <Typography variant="caption" color="textSecondary">Not generated yet</Typography>;
	}

	return <img src={source} alt="video thumbnail" height={height} width={width} onError={onError}/>;
};

Thumbnail.propTypes = {
	video_key: PropTypes.string,
	version: PropTypes.number,
	thumbnail: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]),
	height: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
};

export default Thumbnail;
