import React, { useState } from 'react';
import { Prompt } from 'react-router';
import {
	Box,
	Container,
	Paper,
	LinearProgress,
	Button,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import KidTubeAPI from '../../kidtubeapi';
import Video from './Video';
import Requirements from './Requirements';
import Uploader from './Uploader';
import useCategories from './useCategories';

const REACT_APP_MAX_SIMULTANEOUS_UPLOADS = parseInt(process.env.REACT_APP_MAX_SIMULTANEOUS_UPLOADS);

const Upload = () => {
	const categories = useCategories();
	const [ videos, setVideos ] = useState([]);
	const [ progress, setProgress ] = useState(-1);
	const [ error, setError ] = useState(null);

	const onDrop = files => {
		// console.log('onDrop', { files });
		files = files.map(file => ({
			file,
			title: '',
			description: '',
			category: '',
		}));
		setVideos([ ...videos, ...files ]);
	};

	const clear = () => {
		// TODO: are you sure?
		console.log('clear');
		setVideos([]);
	};

	const upload = () => {
		if (progress > -1) {
			console.log('upload in progress: ignoring');
			return;
		}

		//indicate upload is in progress
		setProgress(0);

		KidTubeAPI.upload({ videos, onProgress: handleUpdatedProgress })
		.then(() => {
			toast.success('Upload successful!');
			clear();
		})
		.catch(error => {
			console.error(error);
			setError(JSON.stringify(error, null, 2));
		})
		.finally(() => {
			// indicate upload is not in progress (complete or error)
			setProgress(-1);
		});
	};

	const handleClose = () => setError(null);

	const handleEdit = (index, property, value) => {
		let newVideos = [ ...videos ];
		newVideos[index][property] = value;
		setVideos(newVideos);
	};

	const handleDelete = index => {
		let newVideos = [ ...videos ];
		newVideos.splice(index,1);
		setVideos(newVideos);
	};

	const handleUpdatedProgress = (index, { loaded, total }) => {
		let percentage = Math.round( ( (loaded / total) + index ) / videos.length * 100);
		console.log({ loaded, total, videos: videos.length, index, percentage });
		setProgress(percentage);
	};

	// ensure each video has a title, description, and category
	const readyToUpload = videos.length > 0 && videos.every(({ title, description, category }) => title.length > 0 && description.length > 0 && category.length > 0);

	const noMoreVideos = videos.length >= REACT_APP_MAX_SIMULTANEOUS_UPLOADS;

	return (
		<Box py={3}>
			<Container>
				<Box mb={2}>
					<Requirements />
				</Box>
				<Paper>
					<Box px={5} py={3}>
						{videos && videos.map((video, i) => (
							<Video
								key={video.file.preview}
								categories={categories}
								video={video}
								index={i}
								onEdit={handleEdit}
								onDelete={handleDelete}
							/>
						))}
						<Progress progress={progress}/>
						<Uploader
							onDrop={onDrop}
							disabled={noMoreVideos}
							maxUploads={REACT_APP_MAX_SIMULTANEOUS_UPLOADS}
						/>
						<Box textAlign='center' my={1}>
							<Typography variant='h6'>Upload up to {REACT_APP_MAX_SIMULTANEOUS_UPLOADS} videos simultaneously</Typography>
							<Typography variant='h6'>All fields are required</Typography>
						</Box>
						<Box display='flex' flexDirection='row' justifyContent='space-evenly'>
							<Button
								variant="contained"
								color="primary"
								size='large'
								onClick={upload}
								disabled={!readyToUpload}
							>
								Upload
							</Button>
							<Button
								variant="contained"
								color="inherit"
								size='large'
								onClick={clear}>
								Cancel
							</Button>
						</Box>
					</Box>
				</Paper>
				<Dialog open={Boolean(error)} onClose={handleClose}>
					<DialogTitle>Error occured during upload</DialogTitle>
					<DialogContent>
						<pre>{error}</pre>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary" autoFocus>Ok</Button>
					</DialogActions>
				</Dialog>
			</Container>
			<Prompt when={progress > -1} message="Your video(s) have not finished uploading, are you sure you want to leave?" />
		</Box>
	);
};


const Progress = ({ progress }) => {
	if (progress < 0) {
		return null;
	}
	return (
		<Box my={2}>
			<LinearProgress variant='determinate' value={progress}/>
		</Box>
	);
};

Progress.propTypes = {
	progress: PropTypes.number.isRequired,
};

export default Upload;
