import React from 'react';
import { Box } from '@material-ui/core';
import { Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import AuthRoute from '../AuthRoute';
import Header from './Header';
import MyVideos from './Videos/MyVideos';
import Upload from './Upload/Upload';
import Views from './Views/Views';
import Profile from './Profile/Profile';

const App = () => (
	<>
		<Header isMobile={isMobile}/>
		<Box pt={10}>
			<Switch>
				<AuthRoute exact path='/'>
					<MyVideos/>
				</AuthRoute>
				<AuthRoute path='/upload'>
					<Upload/>
				</AuthRoute>
				<AuthRoute path='/views/:_id'>
					<Views/>
				</AuthRoute>
				<AuthRoute path='/profile'>
					<Profile/>
				</AuthRoute>
			</Switch>
		</Box>
	</>
);

export default App;
