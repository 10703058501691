import React, { useState } from 'react';
import {
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	Typography,
	ExpansionPanelDetails,
	TextField,
	Button,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import KidTubeAPI from '../../kidtubeapi';
import Auth from '../../Auth';

const inputProps = {
	autoCorrect: 'off',
	autoCapitalize: 'off',
	spellCheck: false,
};

const ChangePassword = () => {
	const theme = useTheme();
	const history = useHistory();
	const [ oldPassword, setOldPassword ] = useState('');
	const [ newPassword, setNewPassword ] = useState('');

	const changePassword = async () => {
		const { status, message } = await KidTubeAPI.changePassword(oldPassword, newPassword);
		const isSuccessful = status === 200;

		const options = {
			type: isSuccessful ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
			position: toast.POSITION.BOTTOM_RIGHT,
		};

		if (isSuccessful) {
			options.onClose = () => {
				Auth.signOut();

				//redirect home
				history.push('/');
			};
		}

		// alert user of result
		toast(message, options);

		if (isSuccessful) {
			// clear passwords
			setOldPassword('');
			setNewPassword('');
		}
	};

	return (
		<Box my={2}>
			<ExpansionPanel style={{ backgroundColor: theme.palette.grey['300'] }}>
				<ExpansionPanelSummary expandIcon={<ExpandMore/>}>
					<Typography variant="h5">Change Password</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<Box display="flex" flexDirection="column" minWidth="50%">
						<Box mb={1}>
							<TextField
								variant="outlined"
								fullWidth
								label="Current Password"
								InputProps={inputProps}
								onChange={evt => setOldPassword(evt.target.value)}
								value={oldPassword}
							/>
						</Box>
						<Box mb={1}>
							<TextField
								variant="outlined"
								fullWidth
								label="New Password"
								InputProps={inputProps}
								onChange={evt => setNewPassword(evt.target.value)}
								value={newPassword}
							/>
						</Box>
						<Button variant="contained" color="primary" onClick={changePassword}>Change Password</Button>
					</Box>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</Box>
	);
};

export default ChangePassword;
