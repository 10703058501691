const ITEM = 'user';

const Storage = {
	save: user => {
		localStorage.setItem(ITEM, JSON.stringify(user || {}));
	},
	read: () => {
		const text = localStorage.getItem(ITEM);
		return text && text !== '' ? JSON.parse(text) : null;
	},
	delete: () => {
		localStorage.removeItem(ITEM);
	},
};

export default Storage;
