import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	DialogActions,
	Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const DeleteVideo = ({ open, onClose, onDelete }) => {
	return (
		<Dialog onClose={onClose} open={open}>
			<DialogTitle>Delete video?</DialogTitle>
			<DialogContent>
				<Typography>Once you delete this video there is no way to recover it</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={onClose}>Cancel</Button>
				<Button color="secondary" onClick={onDelete}>Yes, delete it</Button>
			</DialogActions>
		</Dialog>
	);
};

DeleteVideo.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default DeleteVideo;
