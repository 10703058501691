import React from 'react';
import { Box, Grid, Typography, TextField, Select, MenuItem, Button, Divider } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import PropTypes from 'prop-types';

const Video = ({ categories, video, index, onEdit, onDelete }) => {
	const { file, title, description, category } = video;
	const { preview, size } = file;
	const humanReadableSize = `${(size / 1000 / 1000 / 1000).toFixed(3)} GB`;

	return (
		<Box pb={1}>
			<Box py={1}>
				<Grid container direction='row' alignItems='center'>
					<Grid item xs={3}>
						<video src={preview} controls width='100%'/>
					</Grid>
					<Grid item xs={8}>
						<Box px={4}>
							<Box mb={1}>
								<TextField
									label='Title'
									fullWidth
									variant='outlined'
									value={title}
									onChange={e => onEdit(index, 'title', e.target.value)}
								/>
							</Box>
							<Box mb={1}>
								<TextField
									label='Description'
									fullWidth
									multiline
									variant='outlined'
									value={description}
									onChange={e => onEdit(index, 'description', e.target.value)}
								/>
							</Box>
							<Box mb={1}>
								<Select
									fullWidth
									variant='outlined'
									value={category}
									onChange={e => onEdit(index, 'category', e.target.value)}
								>
									<MenuItem value='' disabled>Category</MenuItem>
									{categories && categories.map(cat => <MenuItem key={cat._id} value={cat._id}>{cat.name}</MenuItem>)}
								</Select>
							</Box>
							<Box mb={1}>
								<Typography variant='h6' color='textSecondary'>{humanReadableSize}</Typography>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={1}>
						<Box textAlign="center">
							<Button onClick={() => onDelete(index)}><HighlightOff fontSize='large'/></Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Divider />
		</Box>
	);
};

Video.propTypes = {
	categories: PropTypes.array.isRequired,
	video: PropTypes.shape({
		file: PropTypes.object.isRequired,
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		category: PropTypes.string.isRequired,
	}),
	index: PropTypes.number.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default Video;
