import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
	palette: {
		primary: {
			main: '#EE5934',
		},
		secondary: {
			main: '#999999',
		},
	},
});
