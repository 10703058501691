import React, { useState } from 'react';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import Login from './Login';
import ResetPassword from './ResetPassword';

const LoginModal = () => {
	const [ open, setOpen ] = useState(false);
	const [ showLogin, setShowLogin ] = useState(true);

	const close = () => {
		setOpen(false);
		setShowLogin(true);
	};

	const resetPassword = () => setShowLogin(false);

	const login = () => setShowLogin(true);

	return (
		<>
			<Button variant="contained" size="large" style={{ backgroundColor: '#fff', color: '#EE5934' }} onClick={() => setOpen(!open)}>Login</Button>
			<Dialog
				fullWidth
				maxWidth="xs"
				open={open}
				onClose={close}
			>
				<DialogContent>
					{showLogin ? <Login onResetPassword={resetPassword} onClose={close}/> : <ResetPassword onLogin={login}/>}
				</DialogContent>
			</Dialog>
		</>
	);
};

export default LoginModal;
