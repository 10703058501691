import { useState, useEffect } from 'react';
import Auth from '../../Auth';
import KidTubeAPI from '../../kidtubeapi';

const useStats = () => {
	const [ stats, setStats ] = useState({
		total: 0,
		missing: 0,
		status: {
			approved: 0,
			declined: 0,
			pending: 0,
		},
		conversion: {
			success: 0,
			pending: 0,
			error: 0,
		},
	});

	useEffect(() => {
		KidTubeAPI.getStats(Auth.token())
		.then(setStats)
		.catch(err => console.log('Error getting stats: ', err));
	}, []);

	return stats;
};

export default useStats;
