import React from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';

const PreviewVideo = ({ onClose, url }) => {
	return (
		<Dialog maxWidth="lg" onClose={onClose} open={Boolean(url)}>
			<video src={url} height='100%' controls/>
		</Dialog>
	);
};

PreviewVideo.propTypes = {
	onClose: PropTypes.func.isRequired,
	url: PropTypes.string,
};

export default PreviewVideo;
