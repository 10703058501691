import React from 'react';
import { CircularProgress, Paper, Box, Grid, Tooltip, Typography } from '@material-ui/core';
import useStats from './useStats';

const Stats = () => {
	const stats = useStats();

	const rate = (x, y) => {
		return x + y === 0 ? 0 : Math.round(x / (x + y) * 100);
	};

	if (!stats) {
		return <CircularProgress/>;
	}

	const { total, missing, status, conversion } = stats;
	return (
		<Paper variant="outlined">
			<Box textAlign="center" p={1}>
				<Grid container direction="column" spacing={1}>
					<Grid item>
						<Box display="flex" flexDirection="row" justifyContent="space-around">
							<Box>
								<Typography variant="h3">{total}</Typography>
								<Typography variant="subtitle1">Total</Typography>
							</Box>
							<Box>
								<Typography variant="h3">{rate(status.approved, status.declined)} %</Typography>
								<Typography variant="subtitle1">Approval Rate</Typography>
							</Box>
						</Box>
					</Grid>
					<Grid item>
						<Grid container direction='row'>
							<Grid item xs={6} sm={3}>
								<Box bgcolor="success.main" p={1}>
									<Tooltip title="Approved" enterTouchDelay={0}>
										<Typography variant="h4">{status.approved}</Typography>
									</Tooltip>
								</Box>
							</Grid>
							<Grid item xs={6} sm={3}>
								<Box bgcolor="info.main" p={1}>
									<Tooltip title="Pending" enterTouchDelay={0}>
										<Typography variant="h4">{status.pending}</Typography>
									</Tooltip>
								</Box>
							</Grid>
							<Grid item xs={6} sm={3}>
								<Box bgcolor="error.main" p={1}>
									<Tooltip title="Declined" enterTouchDelay={0}>
										<Typography variant="h4">{status.declined}</Typography>
									</Tooltip>
								</Box>
							</Grid>
							<Grid item xs={6} sm={3}>
								<Box bgcolor="warning.main" p={1}>
									<Tooltip title="Missing" enterTouchDelay={0}>
										<Typography variant="h4">{missing}</Typography>
									</Tooltip>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Box display="flex" flexDirection="row" justifyContent="space-around">
							{conversion.pending > 0 &&
							<Box bgcolor="warning.main" p={1} width="50%">
								<Tooltip title="Conversion Pending" enterTouchDelay={0}>
									<Typography variant="h4">{conversion.pending}</Typography>
								</Tooltip>
							</Box>
							}
							{conversion.error > 0 &&
							<Box bgcolor="error.main" p={1} width="50%">
								<Tooltip title="Conversion Error" enterTouchDelay={0}>
									<Typography variant="h4">{conversion.error}</Typography>
								</Tooltip>
							</Box>
							}
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
export default Stats;
