import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Register from './Public/Register';
import App from './Authenticated/App';
import AuthRoute from './AuthRoute';
import theme from './theme';

toast.configure({
	position: toast.POSITION.TOP_CENTER,
});

export default function Authentication() {
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Switch>
					<Route path="/register">
						<Register/>
					</Route>
					<AuthRoute path="/">
						<App/>
					</AuthRoute>
				</Switch>
			</Router>
		</ThemeProvider>
	);
}
