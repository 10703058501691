import React from 'react';
import { Box, Container, Card, CardContent } from '@material-ui/core';
import User from './User';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';

const Profile = () => (
	<Box py={3}>
		<Container>
			<Card style={{ padding: 12 }}>
				<CardContent>
					<User/>
					<ChangePassword/>
					<DeleteAccount/>
				</CardContent>
			</Card>
		</Container>
	</Box>
);

export default Profile;
