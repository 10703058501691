import React, { useState } from 'react';
import {
	Box,
	Typography,
	Paper,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Video from './Video';
import PreviewVideo from './PreviewVideo';
import EditVideo from './EditVideo';
import DeleteVideo from './DeleteVideo';

const { REACT_APP_MEDIA_URL } = process.env;

const VideoList = ({ videos, onUpdate, onDelete }) => {
	const [ url, setUrl ] = useState(null);
	const [ videoToEdit, setVideoToEdit ] = useState({});
	const [ videoToDelete, setVideoToDelete ] = useState(null);

	const onClose = () => {
		setVideoToEdit({});
	};

	const setVideoPreview = (key, version) => {
		setUrl(key && version ? `${REACT_APP_MEDIA_URL}/${key}_${version}/hls/${key}_${version}.m3u8` : null);
	};

	const editVideo = video => {
		onUpdate(video)
		.then(() => {
			setVideoToEdit({});

			toast.success('Video updated');
		})
		.catch(error => console.warn(error));
	};

	const removeVideo = () => {
		onDelete(videoToDelete)
		.then(() => {
		// close dialog
			setVideoToDelete(null);

			// Indicate video deletion success
			toast.success('Video deleted');
		})
		.catch(error => console.log(error));
	};

	if (!videos || videos.length === 0) {
		return (
			<Box textAlign="center">
				<Typography>No Videos</Typography>
			</Box>
		);
	}

	return (
		<Paper variant="outlined">
			{
				videos && videos.length > 0 && videos.map((video, index) => (
					<Video key={video._id} video={video} toPreview={setVideoPreview} toEdit={setVideoToEdit} toDelete={setVideoToDelete} includeBorder={index < videos.length - 1}/>
				))
			}
			<PreviewVideo url={url} onClose={() => setUrl(null)}/>
			<EditVideo video={videoToEdit} onUpdate={editVideo} onClose={onClose}/>
			<DeleteVideo open={Boolean(videoToDelete)} onClose={() => setVideoToDelete(null)} onDelete={removeVideo}/>
		</Paper>
	);
};

VideoList.propTypes = {
	videos: PropTypes.array.isRequired,
	onUpdate: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default VideoList;
