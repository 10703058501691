import React from 'react';
import {
	Tooltip,
	Typography,
	Box,
} from '@material-ui/core';
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineDot,
	TimelineConnector,
	TimelineContent,
	TimelineOppositeContent,
} from '@material-ui/lab';
import PropTypes from 'prop-types';

const VideoTimeLine = ({ status, createdAt, comments, conversionStatus }) => {
	let formattedStatus;
	switch (status) {
		case 0:
			// waiting for review
			formattedStatus = '';
			break;
		case 1:
			// approved
			formattedStatus = 'Approved';
			break;
		case 2:
			formattedStatus = 'Declined';
			break;
		default:
			// unknown
			formattedStatus = 'Unknown status';
			break;
	}
	const createdDate = new Date(createdAt).toLocaleString();

	return (
		<Timeline align="right" style={{ margin: 0 }}>
			<TimelineItem>
				<TimelineOppositeContent>{createdDate}</TimelineOppositeContent>
				<TimelineSeparator>
					<TimelineDot color="primary"/>
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent color="textSecondary">Created</TimelineContent>
			</TimelineItem>
			<TimelineItem>
				<TimelineOppositeContent>
					<Tooltip
						title={
							status === 2 ?
								<Typography variant='body1'>{comments}</Typography> :
								''
						}
						placement='bottom-start'
						enterDelay={0}
						enterTouchDelay={0}
						leaveTouchDelay={5000}
					>
						<Box
							mt={-1}
							p={1}
							borderRadius={5}
							bgcolor={status === 2 ? 'error.main' : ''}
							textAlign={status === 2 ? 'center' : 'left'}
							style={{ cursor: status === 2 ? 'pointer' : '' }}
						>{formattedStatus}</Box>
					</Tooltip>
				</TimelineOppositeContent>
				<TimelineSeparator>
					<TimelineDot variant={status === 0 ? 'outlined' : 'default'} color={status === 1 ? 'primary' : 'grey'}/>
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent>Review</TimelineContent>
			</TimelineItem>
			<TimelineItem>
				<TimelineSeparator>
					<TimelineDot variant={conversionStatus === 1 ? 'default' : 'outlined'} color={conversionStatus === 1 ? 'primary' : 'grey'}/>
				</TimelineSeparator>
				<TimelineContent>Live on KidTube</TimelineContent>
			</TimelineItem>
		</Timeline>
	);
};



VideoTimeLine.propTypes = {
	status: PropTypes.number.isRequired,
	createdAt: PropTypes.string.isRequired,
	comments: PropTypes.string,
	conversionStatus: PropTypes.number.isRequired,
};

export default VideoTimeLine;
