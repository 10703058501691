import React from 'react';
import { Box, Chip } from '@material-ui/core';
import { Face, Mail } from '@material-ui/icons';
import Auth from '../../Auth';

const User = () => {
	const { username, email } = Auth.currentUser();
	return (
		<Box display="flex" flexDirection="row">
			<Box mr={1}>
				<Chip
					icon={<Face/>}
					label={username}
				/>
			</Box>
			<Chip
				icon={<Mail/>}
				label={email}
			/>
		</Box>
	);
};

export default User;
