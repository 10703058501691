import Storage from './Storage';
import KidTubeAPI from './kidtubeapi';

const Auth = {
	signIn: async ({ email, password }) => {
		const { username, token } = await KidTubeAPI.authenticate({ identifier: email, password });
		Storage.save({ email, username, token });
	},
	verify: async ({ email, verificationCode }) => {
		const result = await KidTubeAPI.verify({ email, verificationCode });
		console.log('verify result = ', result);
		const { status, firstName, lastName, token } = result;

		if (status !== 200) {
			return result.error;
		} else {
			Storage.save({ email, firstName, lastName, token });
			return { email, firstName, lastName, token };
		}
	},
	changePassword: async (old_password, new_password) => {
		const token = Auth.token();
		return await KidTubeAPI.changePassword(token, { old_password, new_password });
	},
	currentUser: () => {
		return Storage.read();
	},
	isAuthenticated: () => {
		const user = Auth.currentUser();
		return user && user.token !== '';
	},
	token: () => {
		const user = Auth.currentUser();
		return user && user.token ? user.token : null;
	},
	requestDeletion: async () => {
		const token = Auth.token();
		return await KidTubeAPI.requestDeletion(token);
	},
	signOut: () => {
		Storage.delete();
	},
};

export default Auth;
