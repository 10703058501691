import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const TermsOfUse = ({ onAccept }) => (
	<>
		<h2>Terms and Conditions</h2>
		<p>I, hereby grant permission to KidTube.com, the rights to use my original content, in video or still, and of the likeness and sound of my audio as recorded on audio or videotape with projection on KidTube.com and its mobile app.</p>
		<p>I understand that my content may be copied, exhibited, published or distributed on KidTube.com and waive the right to inspect or approve the finished product wherein my likeness appears. Additionally, I also understand that this material may be used in diverse educational settings within an unrestricted geographic area.</p>
		<p>Photographic, audio or video recordings may be used for the following uses: online / internet videos on KidTube.com, as well as the KidTube mobile app.</p>
		<p>By signing this release, I understand this permission signifies that photographic and/or video recordings of my content may be electronically displayed via the Internet/mobile app or in the public educational setting. I will be consulted about the use of the photographs or video recording for any purpose other than those listed above.</p>
		<p>This release may be terminated at any time by notification in writing. There is no geographic limitation on where these materials may be distributed.</p>
		<p>This release applies to photographic, audio and/or video recordings collected on kidtubeupload.com only. Not all videos uploaded to kidtubeupload.com will be displayed on KidTube.com, only approved videos that meet the KidTube standards will be posted.</p>
		<p>By signing this release, I acknowledge that I am 18 or older, have completely read and fully understand the above release and agree to be bound thereby. I hereby release any and all claims against any person or organization utilizing this material for educational purposes.</p>
		<h3>CONTACT INFORMATION:</h3>
		<p>
			KidTube<br />
			P.O. Box 302055<br />
			Escondido, CA 92030<br />
			<a href="mailto:contactinfo@kidtube.video">contactinfo@kidtube.video</a>
		</p>
		<Button onClick={onAccept} variant="contained" color="primary">Accept</Button>
	</>
);

TermsOfUse.propTypes = {
	onAccept: PropTypes.func.isRequired,
};

export default TermsOfUse;
