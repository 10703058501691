import { useState, useEffect } from 'react';
import KidTubeAPI from '../../kidtubeapi';

const useViews = ({ _id }) => {
	const [ views, setViews ] = useState([ [], [] ]);

	useEffect(() => {
		if (_id) {
			KidTubeAPI.getViews({ _id })
			.then(setViews)
			.catch(err => console.log('error obtaining views: ', { err }));
		}
	}, [ _id ]);

	return {
		views,
	};
};

export default useViews;
