import { useState, useEffect } from 'react';
import KidTubeAPI from '../../kidtubeapi';

const useVideos = () => {
	const [ videos, setVideos ] = useState([]);
	const [ total, setTotal ] = useState(0);
	const [ limit, setLimit ] = useState(10);
	const [ skip, setSkip ] = useState(0);

	useEffect(() => {
		KidTubeAPI.getVideos({ limit, skip })
		.then(result => {
			const { videos, total } = result;
			setVideos(videos);
			setTotal(total[0] ? total[0].total : 0);
		})
		.catch(err => console.log({ err }));
	}, [ limit, skip ]);

	const onLimitChange = newLimit => {
		setSkip(0);
		setLimit(newLimit);
	};

	const updateVideo = async ({ _id, title, description, thumbnail }) => {
		// update video on backend
		await KidTubeAPI.updateVideo(_id, { title, description, thumbnail });

		// update this video in UI
		let oldVideo = videos.find(v => v._id === _id);
		const index = videos.findIndex(v => v._id === _id);
		if (oldVideo && index > -1) {
			oldVideo.title = title;
			oldVideo.description = description;
			oldVideo.thumbnail = thumbnail;
			videos.splice(index, 1, oldVideo);
			setVideos(videos);
		}
	};

	const deleteVideo = async id => {
		try {
			//remove video from backend
			await KidTubeAPI.deleteVideo(id);

			// Remove this video from UI
			setVideos(videos.filter(v => v._id !== id));
		} catch (error) {
			console.log(error);
		}
	};

	return {
		videos,
		total,
		skip,
		setSkip,
		limit,
		onLimitChange,
		updateVideo,
		deleteVideo,
	};
};

export default useVideos;
