import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from './Auth';
import Landing from './Public/Landing';

const AuthRoute = ({ children, ...rest }) => {
	return <Route {...rest} render={() => Auth.isAuthenticated() ? children : <Landing/>}/>;
};

AuthRoute.propTypes = {
	children: PropTypes.element.isRequired,
};

export default AuthRoute;
