import React from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { Container, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';

const MAX_UPLOAD_SIZE_BYTES = 5000000000;

const Uploader = ({ onDrop, disabled }) => {
	const maxFileSizeGB = (MAX_UPLOAD_SIZE_BYTES / 1000 / 1000 / 1000).toFixed(1);
	const onDropRejected = () => {
		toast.error(`File size must be less than ${maxFileSizeGB} GB`, {
			position: toast.POSITION.BOTTOM_RIGHT,
			autoClose: 10000,
		});
	};

	return (
		<Container maxWidth='md'>
			<Dropzone
				className="Dropzone"
				onDrop={onDrop}
				onDropRejected={onDropRejected}
				accept='video/mp4,video/quicktime,video/x-m4v'
				multiple={false}
				maxSize={MAX_UPLOAD_SIZE_BYTES}
				disabled={disabled}
			>
				<Box p={3} mb={2} display='flex' justifyContent='center' alignItems='center' flexDirection='column' color='white' style={{
					backgroundColor: disabled ? 'lightgrey' : '#EE5934' ,
					outline: '2px dashed #fff',
					outlineOffset: '-10px',
					borderRadius: '3px',
				}}>
					<CloudUploadIcon fontSize='large'/>
					<Typography variant="h6" gutterBottom>Add another video: Click or drag file here</Typography>
					<Typography variant="body2">Supported formats: MP4, Quicktime (MOV), and M4V</Typography>
					<Typography variant="body2">Individual file size limit {maxFileSizeGB} GB</Typography>
				</Box>
			</Dropzone>
		</Container>
	);
};

Uploader.propTypes = {
	onDrop: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
};

export default Uploader;
