import React, { useState } from 'react';
import { TextField, Button, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Auth from '../Auth';

const style = {
	backgroundColor: 'white',
};

const Login = ({ onResetPassword, onClose }) => {
	const history = useHistory();
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');

	const login = () => {
		Auth.signIn({ email, password })
		.then(() => {
			// clear email and password
			setEmail('');
			setPassword('');
			onClose();

			// redirect to home
			history.push('/');
		})
		.catch(err => {
			toast.error(err.message || 'Incorrect email/password');
		});
	};

	return (
		<>
			<TextField
				variant="filled"
				style={style}
				label="Email"
				fullWidth
				onChange={evt => setEmail(evt.target.value)}
			/>
			<TextField
				variant="filled"
				style={style}
				type="password"
				label="Password"
				fullWidth
				onChange={evt => setPassword(evt.target.value)}
			/>
			<Box my={2} display="flex" flexDirection="row" justifyContent="space-between">
				<Button variant="contained" color="primary" size="large" onClick={login}>Login</Button>
				<Button variant="contained" onClick={onResetPassword}>Reset Password</Button>
			</Box>
		</>
	);
};

Login.propTypes = {
	onResetPassword: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default Login;
