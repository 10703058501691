import React from 'react';
import { Box, Paper, Grid, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const Pagination = ({ limit, onChange, total, skip, setSkip }) => {
	const decreaseSkip = () => {
		let newSkip = skip - limit;
		if (newSkip < 0) {
			newSkip = 0;
		}
		setSkip(newSkip);
	};

	const increaseSkip = () => setSkip(skip + limit);

	return (
		<Box my={1}>
			<Paper variant="outlined">
				<Box p={2}>
					<Grid container direction="row" justify="space-between" alignItems="center">
						<Grid item>
							<InputLabel>Videos Per Page</InputLabel>
							<Select
								value={limit}
								onChange={e => onChange(parseInt(e.target.value))}
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={25}>25</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</Grid>
						<Grid item>
							<Button variant="contained" disabled={skip <= 0} onClick={decreaseSkip}>Back</Button>
							<Button variant="contained" disabled={skip + limit >= total} onClick={increaseSkip}>Next</Button>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Box>
	);
};

Pagination.propTypes = {
	limit: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	total: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired,
};

export default Pagination;
