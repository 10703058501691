import React, { useState } from 'react';
import {
	Box,
	Container,
	Grid,
	Card,
	CardContent,
	Typography,
	InputLabel,
	Select,
	MenuItem,
	CardActions,
	TextField,
	FormControlLabel,
	Checkbox,
	Button,
	Link,
	Dialog,
	DialogContent,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import KidTubeAPI from '../kidtubeapi';
import TermsOfUse from './TermsOfUse';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const inputProps = {
	autoCorrect: 'off',
	autoCapitalize: 'off',
	spellCheck: false,
};

const Register = () => {
	const [ name, setName ] = useState('');
	const [ username, setUsername ] = useState('');
	const [ birthMonth, setBirthMonth ] = useState('');
	const [ birthDate, setBirthDate ] = useState('');
	const [ birthYear, setBirthYear ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ tosAccepted, setTosAccepted ] = useState(false);
	const [ showTermsOfService, setShowTermsOfService ] = useState(false);

	const handleBirthYear = evt => {
		const value = evt.target.value;
		if (value.match(/^\d{0,4}$/)) {
			setBirthYear(value);
		}
	};

	const valid =
		name.length > 0 &&
		username.length > 0 &&
		birthMonth !== '' &&
		birthDate !== '' &&
		birthYear.length === 4 && !isNaN(parseInt(birthYear)) && parseInt(birthYear) > 1900 &&
		email.length > 0 &&
		emailRegex.test(email) &&
		password.length > 0 &&
		tosAccepted;

	const toggleTermsOfService = evt => {
		if (evt.target.checked) {
			setShowTermsOfService(true);
		} else {
			setTosAccepted(false);
		}
	};

	const acceptToS = () => {
		setTosAccepted(true);
		setShowTermsOfService(false);
	};

	const register = () => {
		// validate birthdate
		const testDate = new Date(parseInt(birthYear),parseInt(birthMonth)-1,parseInt(birthDate));
		if (isNaN(testDate.getTime())) {
			// invalid date
			toast.error('Invalid birth date');
			return;
		}

		// ensure birthdate >= 18 years from today
		let minDate = new Date();
		minDate.setFullYear(minDate.getFullYear()-18);
		if (testDate.getTime() >= minDate) {
			toast.error('Sorry, you must be at least 18 years old');
			return;
		}

		const birthdate = testDate.toJSON().split('T')[0];

		KidTubeAPI.register({ username, name, email, password, birthdate })
		.then(() => {
			// clear all user data
			setName('');
			setUsername('');
			setBirthMonth('');
			setBirthDate('');
			setBirthYear('');
			setEmail('');
			setPassword('');
			setTosAccepted(false);

			// indicate success
			toast.success('Successfully registered! Login to continue.');
		})
		.catch(error => {
			console.log('error with register: ', error);
		});
	};

	return (
		<Box py={5}>
			<Container maxWidth="sm">
				<Box textAlign="center">
					<Typography variant="h3" color="textSecondary" gutterBottom>Ready to start creating?</Typography>
				</Box>
				<Card>
					<CardContent>
						<Typography variant="body1" color="primary" gutterBottom>All fields must be entered.</Typography>
						<Grid container direction="column">
							<Grid item>
								<TextField
									label="Full Name"
									fullWidth
									InputProps={inputProps}
									value={name}
									onChange={evt => setName(evt.target.value)}
								/>
							</Grid>
							<Grid item>
								<TextField
									label="Username"
									fullWidth
									InputProps={inputProps}
									value={username}
									onChange={evt => setUsername(evt.target.value)}
								/>
							</Grid>
							<Grid item>
								<Box py={1}>
									<Typography variant="body1" color="textSecondary">Birthdate</Typography>
								</Box>
								<Grid container direction="row" spacing={1}>
									<Grid item xs={4}>
										<InputLabel>Month</InputLabel>
										<Select
											fullWidth
											value={birthMonth}
											onChange={evt => setBirthMonth(evt.target.value)}
										>
											<MenuItem value="" disabled></MenuItem>
											<MenuItem value={1}>January</MenuItem>
											<MenuItem value={2}>February</MenuItem>
											<MenuItem value={3}>March</MenuItem>
											<MenuItem value={4}>April</MenuItem>
											<MenuItem value={5}>May</MenuItem>
											<MenuItem value={6}>June</MenuItem>
											<MenuItem value={7}>July</MenuItem>
											<MenuItem value={8}>August</MenuItem>
											<MenuItem value={9}>September</MenuItem>
											<MenuItem value={10}>October</MenuItem>
											<MenuItem value={11}>November</MenuItem>
											<MenuItem value={12}>December</MenuItem>
										</Select>
									</Grid>
									<Grid item xs={4}>
										<InputLabel>Date</InputLabel>
										<Select
											fullWidth
											value={birthDate}
											onChange={evt => setBirthDate(evt.target.value)}
										>
											<MenuItem value="" disabled></MenuItem>
											<MenuItem value={1}>1</MenuItem>
											<MenuItem value={2}>2</MenuItem>
											<MenuItem value={3}>3</MenuItem>
											<MenuItem value={4}>4</MenuItem>
											<MenuItem value={5}>5</MenuItem>
											<MenuItem value={6}>6</MenuItem>
											<MenuItem value={7}>7</MenuItem>
											<MenuItem value={8}>8</MenuItem>
											<MenuItem value={9}>9</MenuItem>
											<MenuItem value={10}>10</MenuItem>
											<MenuItem value={11}>11</MenuItem>
											<MenuItem value={12}>12</MenuItem>
											<MenuItem value={13}>13</MenuItem>
											<MenuItem value={14}>14</MenuItem>
											<MenuItem value={15}>15</MenuItem>
											<MenuItem value={16}>16</MenuItem>
											<MenuItem value={17}>17</MenuItem>
											<MenuItem value={18}>18</MenuItem>
											<MenuItem value={19}>19</MenuItem>
											<MenuItem value={20}>20</MenuItem>
											<MenuItem value={21}>21</MenuItem>
											<MenuItem value={22}>22</MenuItem>
											<MenuItem value={23}>23</MenuItem>
											<MenuItem value={24}>24</MenuItem>
											<MenuItem value={25}>25</MenuItem>
											<MenuItem value={26}>26</MenuItem>
											<MenuItem value={27}>27</MenuItem>
											<MenuItem value={28}>28</MenuItem>
											<MenuItem value={29}>29</MenuItem>
											<MenuItem value={30}>30</MenuItem>
											<MenuItem value={31}>31</MenuItem>
										</Select>
									</Grid>
									<Grid item xs={4}>
										<InputLabel>Year</InputLabel>
										<TextField
											fullWidth
											InputProps={inputProps}
											value={birthYear}
											onChange={handleBirthYear}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<TextField
									label="Email"
									fullWidth
									type="email"
									InputProps={inputProps}
									value={email}
									onChange={evt => setEmail(evt.target.value)}
								/>
							</Grid>
							<Grid item>
								<TextField
									label="Password"
									fullWidth
									InputProps={inputProps}
									value={password}
									onChange={evt => setPassword(evt.target.value)}
								/>
							</Grid>
							<Grid item>
								<FormControlLabel
									value={tosAccepted}
									control={<Checkbox color="primary"/>}
									label="Click to agree to our Terms and Conditions."
									onChange={toggleTermsOfService}
								/>
							</Grid>
							<Grid item>
								<p>
									To register you must agree to the KidTube <Box component='span' color="primary.main" style={{ cursor:'pointer' }} onClick={() => setShowTermsOfService(true)}>Terms and Conditions.</Box> A KidTube representative will contact you for account approval as well as compensation plan details.
								</p>
							</Grid>
							<Grid item>
								<Link href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank" rel="noopener noreferrer">Download your IRS W9 form here</Link> and submit it so KidTube can pay you!
							</Grid>
						</Grid>
					</CardContent>
					<CardActions>
						<Button
							variant="contained"
							color="primary"
							onClick={register}
							disabled={!valid}>
							Register
						</Button>
					</CardActions>
				</Card>
				<AcceptToS open={showTermsOfService} onClose={() => setShowTermsOfService(false)} onAccept={acceptToS}/>
			</Container>
		</Box>
	);
};

const AcceptToS = ({ open, onClose, onAccept }) => (
	<Dialog
		open={open}
		onClose={onClose}
		disableEscapeKeyDown
		disableBackdropClick
	>
		<DialogContent>
			<TermsOfUse onAccept={onAccept}/>
		</DialogContent>
	</Dialog>
);

AcceptToS.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onAccept: PropTypes.func.isRequired,
};

export default Register;
