import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Container, Paper, Button, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import Chart from './Chart';
import useViews from './useViews';

const Views = () => {
	const location = useLocation();
	const { video } = location.state;
	const { views } = useViews({ _id: video._id });
	return (
		<Box py={2}>
			<Container maxWidth="lg">
				<Button href="/" color="primary"><ArrowBack/> Back</Button>
				<Paper variant="outlined">
					<Box p={2}>
						<Typography variant="h3">{video.title}</Typography>
						<Box py={1}>
							<Typography variant='body1'>One &apos;View&apos; is defined as a user watching at least 25% of a video. Users can rewatch videos and this will count as an additional view.</Typography>
							<Chart title='Views' data={views[0]}/>
						</Box>
						<Box py={1}>
							<Typography variant='body1'>One &apos;Click&apos; is defined as a user clicking/tapping on a video.</Typography>
							<Chart title='Clicks' data={views[1]}/>
						</Box>
					</Box>
				</Paper>
			</Container>
		</Box>
	);
};

export default Views;
