import React, { useState } from 'react';
import {
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	Typography,
	ExpansionPanelDetails,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import { ExpandMore, Delete } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import KidTubeAPI from '../../kidtubeapi';
import Auth from '../../Auth';

const DeleteAccount = () => {
	const theme = useTheme();
	const history = useHistory();
	const [ showDialog, setShowDialog ] = useState(false);

	const deleteAccount = async () => {
		setShowDialog(false);

		try {
			await KidTubeAPI.deleteAccount();

			// sign out
			Auth.signOut();

			// redirect to login
			history.push('/');
		} catch (err) {
			console.log({ err });
			toast.error(JSON.stringify(err));
		}
	};

	return (
		<Box my={1}>
			<ExpansionPanel style={{ backgroundColor: theme.palette.grey['300'] }}>
				<ExpansionPanelSummary expandIcon={<ExpandMore/>}>
					<Typography variant="h5">Delete Account</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<Button style={{ backgroundColor: theme.palette.error.dark }} variant="contained" onClick={() => setShowDialog(true)}>
						<Delete style={{ marginRight: 4 }} />
						Delete Account
					</Button>
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<Dialog onClose={() => setShowDialog(false)} open={showDialog}>
				<DialogTitle>Are you sure?</DialogTitle>
				<DialogContent>
					<Typography>Once you delete your account there is no way to recover it.</Typography>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={() => setShowDialog(false)}>Cancel</Button>
					<Button color="secondary" onClick={deleteAccount}>Yes, delete it</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default DeleteAccount;
