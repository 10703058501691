import React, { useState } from 'react';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import KidTubeAPI from '../kidtubeapi';

const ResetPassword = ({ onLogin }) => {
	const [ email, setEmail ] = useState('');
	const [ complete, setComplete ] = useState(false);

	const handleEmailChange = evt => setEmail(evt.target.value);

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const emailIsValid = email => emailRegex.test(email);

	const resetPassword = () => {
		if (!emailIsValid(email)) {
			toast.error('Invalid email');
			return;
		}

		KidTubeAPI.resetPassword(email)
		.then(() => {
			// clear email
			setEmail('');
			setComplete(true);
		})
		.catch(err => {
			console.log('reset password error = ', err);
			toast.error(err.message || 'Error resetting password');
		});
	};

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<TextField
					variant="filled"
					style={{ backgroundColor:'white' }}
					name="email"
					type="email"
					label="Email"
					fullWidth
					onChange={handleEmailChange}
				/>
			</Grid>
			{complete &&
			<Grid item>
				<Typography variant="body2" color="textSecondary">
					If the email exists in our system, you will receive a reset password email in a few moments.
				</Typography>
			</Grid>
			}
			<Grid item>
				<Grid container direction="row" spacing={2}>
					<Grid item>
						<Button variant="contained" onClick={onLogin}>Back</Button>
					</Grid>
					<Grid item>
						<Button disabled={complete || !emailIsValid(email)} color="primary" variant="contained" onClick={resetPassword}>Reset Password</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

ResetPassword.propTypes = {
	onLogin: PropTypes.func.isRequired,
};

export default ResetPassword;
