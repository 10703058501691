import React from 'react';
import { AppBar, Toolbar, Box, Typography, Tooltip, Button } from '@material-ui/core';
import { VideoLibrary, CloudUpload, AccountBox, ExitToApp } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from '../Auth';

const Header = ({ isMobile }) => {
	const history = useHistory();

	const signOut = () => {
		Auth.signOut();
		history.push('/');
	};

	return (
		<AppBar position="fixed">
			<Toolbar>
				<Box width={1} display="flex" alignItems="center" justifyContent="space-between" p={1}>
					<img
						alt="KidTube Upload"
						src='/KT.png'
						height={60}
					/>
					{!isMobile && <Typography variant="h5" style={{ 'fontFamily': 'ComicKings' }}>Kidtube Upload</Typography>}
					<Box>
						<Tooltip title="My Videos" placement="bottom">
							<Button onClick={()=>history.push('/')}>
								<VideoLibrary/>
							</Button>
						</Tooltip>
						<Tooltip title="Upload" placement="bottom">
							<Button onClick={()=>history.push('/upload')}>
								<CloudUpload/>
							</Button>
						</Tooltip>
						<Tooltip title="Profile" placement="bottom">
							<Button onClick={()=>history.push('/profile')}>
								<AccountBox/>
							</Button>
						</Tooltip>
						<Tooltip title="Logout" placement="bottom">
							<Button onClick={signOut} style={{ color: 'white' }}>
								<ExitToApp/>
							</Button>
						</Tooltip>
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	);
};

Header.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

export default Header;
