import AWS from 'aws-sdk';
import uuid from 'uuid/v1';
import Auth from './Auth';

const {
	REACT_APP_API_URL,
	REACT_APP_REGION,
	REACT_APP_ACCESS_KEY,
	REACT_APP_SECRET_KEY,
	REACT_APP_BUCKET,
	REACT_APP_ACCELERATE_UPLOAD,
	REACT_APP_TIMEOUT,
} = process.env;

// Configure AWS
AWS.config = new AWS.Config({
	region: REACT_APP_REGION,
	accessKeyId: REACT_APP_ACCESS_KEY,
	secretAccessKey: REACT_APP_SECRET_KEY,
	httpOptions: {
		connectTimeout: REACT_APP_TIMEOUT,
		timeout: REACT_APP_TIMEOUT,
	},
});

// Configure AWS S3 object
const s3Config = {
	params: {
		Bucket: REACT_APP_BUCKET,
	},
};

if (REACT_APP_ACCELERATE_UPLOAD === '1') {
	s3Config.endpoint = 's3-accelerate.amazonaws.com';
}

const s3 = new AWS.S3(s3Config);

const authenticate = async ({ identifier, password }) => {
	let status;
	return await fetch(`${REACT_APP_API_URL}/auth`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ identifier, password }),
	})
	.then(r => {
		status = r.status;
		return r.json();
	})
	.then(response => {
		if (status !== 200) {
			throw Error(response.message);
		}
		return response;
	});
};

const register = async (user) => {
	let status;
	return await fetch(`${REACT_APP_API_URL}/register`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(user),
	})
	.then(r => {
		status = r.status;
		return r.json();
	})
	.then(response => {
		if (status !== 200) {
			throw Error(response);
		}
		return response;
	});
};

const resetPassword = async (email) => {
	let status;
	return await fetch(`${REACT_APP_API_URL}/reset`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ email }),
	})
	.then(r => {
		status = r.status;
		return r.json();
	})
	.then(response => {
		if (status !== 200) {
			throw Error(response.message);
		}
		return response;
	});
};

const updateVideo = async (id, video) => {
	let status;
	return await fetch(`${REACT_APP_API_URL}/video/${id}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${Auth.token()}`,
		},
		body: JSON.stringify(video),
	})
	.then(x => {
		status = x.status;
		return x.json();
	})
	.then(result => {
		if (status !== 200) {
			console.error('Error updating video: ', result);
			throw Error(result.error || 'Unknown error');
		}
		return {};
	});
};

const deleteVideo = async (id) => {
	let status;
	return await fetch(`${REACT_APP_API_URL}/video/${id}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${Auth.token()}`,
		},
	})
	.then(r => {
		status = r.status;
		return r.json();
	})
	.then(response => {
		if (status !== 200) {
			throw Error(response.message);
		}
		return response;
	});
};

const getVideos = async ({ limit, skip }) => {
	limit = parseInt(limit) || 10;
	skip = parseInt(skip) || 0;
	let status;
	return await fetch(`${REACT_APP_API_URL}/videos?limit=${limit}&skip=${skip}`, {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${Auth.token()}`,
			'Content-Type': 'application/json',
		},
	})
	.then(r => {
		status = r.status;
		return r.json();
	})
	.then(response => {
		if (status !== 200) {
			throw Error(response.message);
		}
		return response;
	});
};

const getCategories = async () => {
	return await fetch(`${REACT_APP_API_URL}/categories`, {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${Auth.token()}`,
		},
	})
	.then(r => r.json());
};

const getStats = async (token) => {
	let status;
	return await fetch(`${REACT_APP_API_URL}/stats`, {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${token}`,
		},
	})
	.then(r => {
		status = r.status;
		return r.json();
	})
	.then(response => {
		if (status !== 200) {
			throw Error(response);
		}
		return response;
	});
};

const uploadFile = (key, file, callback, index) => {
	// upload to AWS
	const params = {
		Key: key,
		Body: file,
	};
	return s3.upload(params).on('httpUploadProgress', progress => callback(parseInt(index), progress)).promise();
};

const createVideo = ({ title, description, category, key, extension }) => {
	return fetch(`${REACT_APP_API_URL}/video`, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${Auth.token()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			title,
			description,
			categories: [ category ],
			key,
			extension,
		}),
	})
	.then(res => res.ok ? res.json() : Promise.reject(res));
};

const createIssue = (error) => {
	return fetch(`${REACT_APP_API_URL}/issue`, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${Auth.token()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(error),
	});
};

const upload = async ({ videos, onProgress }) => {
	// added to patch false positive with eslint no-unused-var in for loop
	let index = null;
	for (index in videos) {
		const video = videos[index];
		const { file, title, description, category } = video;
		const extension = file.name.split('.').slice(-1)[0].toLowerCase();

		// generate key
		const id = uuid();
		const key = `public/${id}.${extension}`;

		try {
			//upload
			await uploadFile(key, file, onProgress, index);

			//create video record
			await createVideo({ title, description, category, key: id, extension });
		} catch (error) {
			try {
				await createIssue(error);
				throw error;
			} catch (networkError) {
				console.error(networkError);
				throw networkError;
			}
		}
	}
};

const deleteAccount = async () => {
	let status;
	return await fetch(`${REACT_APP_API_URL}/user`, {
		method: 'DELETE',
		headers: {
			'Authorization': `Bearer ${Auth.token()}`,
		},
	})
	.then(r => {
		status = r.status;
		return r.json();
	})
	.then(response => {
		if (status !== 200) {
			throw Error(response);
		}
		return response;
	});
};

const changePassword = async (old_password, new_password) => {
	let status;
	return await fetch(`${REACT_APP_API_URL}/user`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${Auth.token()}`,
		},
		body: JSON.stringify({ old_password, new_password }),
	})
	.then(r => {
		status = r.status;
		return r.json();
	})
	.then(response => ({
		status,
		...response,
	}))
	.catch(err => err.message);
};

const getViews = async ({ _id }) => {
	let status;
	return await fetch(`${REACT_APP_API_URL}/views/${_id}`, {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${Auth.token()}`,
			'Content-Type': 'application/json',
		},
	})
	.then(r => {
		status = r.status;
		return r.json();
	})
	.then(response => {
		if (status !== 200) {
			throw Error(JSON.stringify(response));
		}
		return response;
	});
};

export default {
	authenticate,
	register,
	resetPassword,
	updateVideo,
	deleteVideo,
	getVideos,
	getCategories,
	getStats,
	upload,
	deleteAccount,
	changePassword,
	getViews,
};
