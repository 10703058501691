import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { TimeRange, TimeSeries } from 'pondjs';
import {
	Resizable,
	ChartContainer,
	Charts,
	ChartRow,
	YAxis,
	BarChart,
} from 'react-timeseries-charts';
import PropTypes from 'prop-types';

const Chart = ({ title, label, data }) => {
	const theme = useTheme();
	const [ highlighted, setHighlighted ] = useState(null);
	const now = new Date();
	const [ timeRange, setTimeRange ] = useState(new TimeRange([ now.getTime() - 60*24*60*60*1000, now.getTime() ]));
	const series = new TimeSeries({
		name: title,
		columns: [ 'index', 'value' ],
		points: data,
	});

	if (data.length === 0) {
		return (
			<Box textAlign='center' py={3}>
				<Typography variant="h3">{`No ${title} Data`}</Typography>
			</Box>
		);
	}

	const titleStyle = {
		color: '#EEE',
		fontWeight: 900,
	};

	const containerStyle = {
		background: '#202020',
		borderRadius: 8,
	};

	const axisStyle = {
		ticks: {
			stroke: '#444444',
		},
	};

	const style = {
		'value': {
			normal: {
				fill: 'lightgray',
			},
			highlighted: {
				fill: theme.palette.primary.main,
			},
		},
	};

	const infoStyle = {
		box: {
			fill: 'black',
		},
	};

	const stemStyle = {
		stroke: theme.palette.primary.main,
	};

	const info = [ {
		label: label || title,
		value: highlighted ? `${highlighted.event.get('value')}` : '',
	} ];

	return (
		<Resizable>
			<ChartContainer
				title={title}
				titleStyle={titleStyle}
				timeRange={timeRange}
				enablePanZoom
				onTimeRangeChanged={setTimeRange}
				paddingTop={5}
				paddingLeft={-30}
				maxTime={new Date()}
				minTime={new Date('2019-10-28')}
				minDuration={7*24*60*60*1000}
				format="%b %d"
				style={containerStyle}
			>
				<ChartRow height="400" axisMargin={10}>
					<YAxis
						id="axis"
						min={0}
						max={series.max()}
						width="80"
						type="linear"
						showGrid={true}
						hideAxisLine
						absolule={true}
						style={axisStyle}
					/>
					<Charts>
						<BarChart
							axis="axis"
							info={info}
							highlighted={highlighted}
							onHighlightChange={setHighlighted}
							series={series}
							offset={-15}
							style={style}
							infoStyle={infoStyle}
							stemStyle={stemStyle}
						/>
					</Charts>
				</ChartRow>
			</ChartContainer>
		</Resizable>
	);
};

Chart.propTypes = {
	title: PropTypes.string.isRequired,
	label: PropTypes.string,
	data: PropTypes.array.isRequired,
};

export default Chart;
