import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import useVideos from './useVideos';
import Stats from './Stats';
import Pagination from './Pagination';
import VideoList from './VideoList';

const MyVideos = () => {
	const { videos, total, skip, setSkip, limit, onLimitChange, updateVideo, deleteVideo } = useVideos();
	return (
		<Box py={3}>
			<Container maxWidth="xl">
				<Grid container directon="column" spacing={2}>
					<Grid item xs={12}>
						<Stats />
					</Grid>
					<Grid item xs={12}>
						<Pagination limit={limit} onChange={onLimitChange} skip={skip} setSkip={setSkip} total={total}/>
					</Grid>
					<Grid item xs={12}>
						<VideoList videos={videos} onUpdate={updateVideo} onDelete={deleteVideo}/>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default MyVideos;
