import React from 'react';
import { Paper, Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ChildCare, ShortText, SpeakerNotesOff, NotificationsOff, MoneyOff, NotInterested } from '@material-ui/icons';

const Requirements = () => (
	<Paper>
		<Box p={3}>
			<Typography variant='h3' gutterBottom>Content Requirements</Typography>
			<Typography variant='subtitle1'>KidTube&apos;s team of moms watch every video in its entirety before allowing content to appear on the platform. The following requirements must be followed in order to receive content approval.</Typography>
			<List>
				<ListItem>
					<ListItemIcon><ChildCare/></ListItemIcon>
					<ListItemText>Appropriate content for viewers 12 years old and under</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemIcon><ShortText/></ListItemIcon>
					<ListItemText>Limit video descriptions to 1-3 sentences</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemIcon><SpeakerNotesOff/></ListItemIcon>
					<ListItemText>Do not ask viewers to comment</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemIcon><NotificationsOff/></ListItemIcon>
					<ListItemText>Do not ask to subscribe</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemIcon><MoneyOff/></ListItemIcon>
					<ListItemText>No sponsored videos or advertising</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemIcon><NotInterested/></ListItemIcon>
					<ListItemText>Do not ask viewers to go to your page (YouTube, Facebook, etc.) or add any links</ListItemText>
				</ListItem>
			</List>
		</Box>
	</Paper>
);

export default Requirements;
