import { useState, useEffect } from 'react';
import KidTubeAPI from '../../kidtubeapi';

const useCategories = () => {
	const [ categories, setCategories ] = useState([]);

	useEffect(() => {
		KidTubeAPI.getCategories()
		.then(setCategories)
		.catch(err => {
			console.log('get categories error: ', err);
		});
	}, []);

	return categories;
};

export default useCategories;
