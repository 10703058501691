import React from 'react';
import { Box, Grid, Link } from '@material-ui/core';
import { Phone, Email, Lock } from '@material-ui/icons';

const iconStyle = {
	color: 'white',
	marginRight: '1rem',
};

const linkStyle = {
	color: 'white',
};

const Footer = () => (
	<Box py={2} bgcolor="primary.main">
		<Grid container direction="row" spacing={1}>
			<Grid item xs={12} sm={4}>
				<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
					<Phone style={iconStyle}/>
					<Link href="tel:+18555438823" style={linkStyle}>1 (855) KIDTUBE</Link>
				</Box>
			</Grid>
			<Grid item xs={12} sm={4}>
				<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" color="textSecondary">
					<Email style={iconStyle}/>
					<Link href="mailto:contactinfo@kidtube.video" style={linkStyle}>contactinfo@kidtube.video</Link>
				</Box>
			</Grid>
			<Grid item xs={12} sm={4}>
				<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" color="textSecondary">
					<Lock style={iconStyle}/>
					<Link href="/privacy.html" target="_blank" rel="noopener noreferrer" style={linkStyle}>Privacy</Link>
				</Box>
			</Grid>
		</Grid>
	</Box>
);

export default Footer;
