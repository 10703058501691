import React from 'react';
import {
	createMuiTheme,
	AppBar,
	Toolbar,
	Box,
	Container,
	Typography,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import LoginModal from './LoginModal';
import Register from './Register';
import Footer from './Footer';

const theme = createMuiTheme({
	typography: {
		fontFamily: 'Georgia',
	},
	palette: {
		primary: {
			main: '#EE5934',
		},
	},
});

const isMobile = window.innerWidth <= 450;

const Landing = () => (
	<ThemeProvider theme={theme}>
		<Header/>
		<Banner/>
		<StandardsSection isMobile={isMobile}/>
		<FAQs isMobile={isMobile}/>
		<SignUp/>
		<Footer/>
	</ThemeProvider>
);

const Header = () => (
	<AppBar position="fixed">
		<Toolbar>
			<Box width={1} p={1} display="flex" alignItems="center" justifyContent="space-between">
				<img
					alt="KidTube Upload"
					src='/KT.png'
					height={60}
				/>
				{!isMobile && <Typography variant="h5" style={{ 'fontFamily': 'ComicKings' }}>Kidtube Upload</Typography>}
				<Box>
					<LoginModal/>
				</Box>
			</Box>
		</Toolbar>
	</AppBar>
);

const Banner = () => (
	<Box mt={9}>
		<img src="/login.jpg" alt="banner" width="100%"/>
	</Box>
);

const StandardsSection = ({ isMobile }) => {
	const body = isMobile ? <Standards isMobile={isMobile}/> : (
		<Container maxWidth="md">
			<Standards isMobile={isMobile}/>
		</Container>
	);

	return (
		<Box py={4} px={0} textAlign="center" bgcolor="grey.500" color="white">
			<Box py={2}>
				<img src="/KT.png" alt="KT logo" width="150px"/>
			</Box>
			{body}
		</Box>
	);
};

StandardsSection.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

const Standards = ({ isMobile }) => (
	<>
		<Typography variant={isMobile ? 'h4' : 'h1'} gutterBottom >Want to Become a KidTube Creator?</Typography>
		<Typography variant="h6">Awesome! Below are the guidelines we require our Creators to follow.</Typography>
		<Typography variant="h6">Any and all uploaded videos must not contain ANY of the following:</Typography>
		<Typography variant="h6">Nudity</Typography>
		<Typography variant="h6">Violence</Typography>
		<Typography variant="h6">Profanity</Typography>
		<Typography variant="h6">Sexual Content</Typography>
		<Typography variant="h6">Suggestive Behavior</Typography>
	</>
);

Standards.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

const FAQs = ({ isMobile }) => (
	<>
		<Box color="text.secondary" textAlign="center" p={5}>
			<Typography variant="h2">FAQs</Typography>
		</Box>
		<Box bgcolor="grey.300" py={isMobile ? 2 : 10} px={isMobile ? 2 : 8}>
			<Box py={3}>
				<Typography variant="h5" gutterBottom>I&apos;ve uploaded my videos, now what?</Typography>
				<Typography variant="h6" color="textSecondary">Once your videos are uploaded, our team of moms will begin curating through every video. Videos are NOT seen by our subscribers until your video has been approved.</Typography>
			</Box>
			<Box py={3}>
				<Typography variant="h5" gutterBottom>How long will it take before my videos get released?</Typography>
				<Typography variant="h6" color="textSecondary">Once your videos have been approved, they will be released and made viewable to our subscribers. However, if a view does not meet our standards and does not adhere to our guidelines, as listed above, it will not get approved and we will not release it.</Typography>
			</Box>
			<Box py={3}>
				<Typography variant="h5" gutterBottom>Will I get paid for my videos?</Typography>
				<Typography variant="h6" color="textSecondary">KidTube offers monetization for your videos.</Typography>
			</Box>
		</Box>
	</>
);

FAQs.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

const SignUp = () => (
	<Box p={2} textAlign="center">
		<Register/>
	</Box>
);

export default Landing;
